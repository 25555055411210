<template>
    <div v-if="item" class="page-content">
        <page-breadcrumb title="Treasure Garden Detail" class="mb-2" :items="breadcrumbItems" />
        <section class="mb-2">
        <b-card class="mt-1">
            <form-generator :model="item" :schema="item_form_schema" />
        </b-card>
        <b-card>
            <dynamic-zone ref="tasks" 
                :items="item.treasures" 
                :default-data="{type: null, rewards: []}"
                :computeTitleFn="taskTitle"
            >
                <template v-slot="{ item: task }">
                    <div class="mb-1">
                        <p>Key Reward</p>
                        <b-form-select v-model="task.type" :options="reward_type_options"></b-form-select>
                    </div>
                    <div v-if="task.type === 2" class="mb-1">
                        <p>Product ID</p>
                        <b-input v-model="task.product_id"></b-input>
                    </div>
                    <div class="mb-2">
                        <h4>Rewards</h4>
                        <reward-select 
                            :items="task.rewards" 
                            :options="usable_item_options" 
                            :id="'rewards'"
                            :default_data="{reward_info: {reward_id: null, type: null, category: null}, quantity: null}" 
                            :rewards="rewards"
                        />
                    </div>
                </template>
            </dynamic-zone>
        </b-card>
        </section>
        <action-buttons
            update-button
            back-button
            delete-button
            @update="update"
            :is-updating="isUpdating"
            @delete="deleteItem"
            :is-deleting="isDeleting"
        />
    </div>  
    
</template>
<script>
const item_form_schema = [
    {
        cols: 12,
        fields: [
            { label: 'Name', field: 'name', validate: { required: true } },
            { label: 'Enable', field: 'is_enable', input_type: 'switch'},
            { label: 'Banner URL', field: 'banner_url' },
            { label: 'Start Time', field: 'start_time', input_type: 'datetime'},
            { label: 'End Time', field: 'end_time', input_type: 'datetime'}
        ]
    }
];
import service from '../service';
import supportItemService from '../../support_item/service';
import RewardSelect from '@core/components/reward-select/RewardSelect'
export default {
    components: {
        RewardSelect
    },
    data(){
        return{
            item_form_schema,
            item: null,
            isUpdating: false,
            isDeleting: false,
            usable_item_options: [],
            rewards: [],
            reward_type_options: [{text: 'Free Reward', value: 1}, {text: 'Product Reward', value: 2}]
        }
    },
    computed: {
        itemId() {
            return this.$route.params.id;
        },
        breadcrumbItems() {
            let items = [
                {
                    text: 'Treasure Garden',
                    to: { name: 'treasure-garden-list' },
                },
                {
                    text: `${this.item ? this.item.title : this.itemId}`, active: true
                },
            ]
            return items
        },
        authLanguage() {
            return this.$store.getters["auth/language"];
        },
    },
    created(){
        this.getUsableItem();
        this.getDetail();
    },
    methods:{
        async getDetail(){
            this.item = await service.get({id: this.itemId});
        },
        async update() {
            this.isUpdating = true;
            let data = {...this.item};
            await service.update({
                data: JSON.stringify(data),
            });
            this.getDetail();
            this.isUpdating = false;
        },
        async deleteItem() {
            await service.delete({ id: this.itemId });
            this.$router.push({ name: "treasure-garden-list" });
        },
        async getUsableItem(){
			let data = await supportItemService.getAll();
			if (data){
				if (this.usable_item_options.length){
					this.usable_item_options = [];
				}
				this.usable_item_options = data.map((item)=> {
					return {
						text: item.title || item.name,
						value: item.type
					}
				})
                this.rewards = data.map((item)=> {
					return {
						reward_id: item._id,
                        type: item.type,
                        category: item.category
					}
				})
                this.usable_item_options.push(...[{text: 'Coin', value: 'coin'},{text: 'Unlimited Lives', value: 'unlimited_lives'}])
                this.rewards.push(...[{type: 'coin'}, {type: 'unlimited_lives'}])
			}
		},
        select_fnc(selected){
            let value = selected.value;
            return {
                reward_id: value.reward_id || null,
                type: value.type,
                category: value.category,
                reward_info: value
            }
        },
        taskTitle(item, index){
            return `Task ${index}`
        }
    }
}
</script>
<style>
.reward-border {
    border: 2px solid black;
}
</style>